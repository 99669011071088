import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const {
  setUsers: setUsersAction,
 
} = usersSlice.actions;

export default usersSlice.reducer;
