import React, { useState, useEffect } from "react";
import { Row, Typography, Select, Spin, Space } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import openNotification from "../../Components/Notifications";
import { setUsersAction } from "../../redux/reducers/usersReducer";
import { getAllUsers } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import { IoReloadOutline } from "react-icons/io5";
import { Table } from "ant-table-extensions";
import { SearchOutlined } from "@ant-design/icons";
import { MdVerified } from "react-icons/md";

const { Text, Title } = Typography;

const Users = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { users } = useSelector((state) => state.users);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (users?.length === 0) {
      getUsers();
    }
  }, []);

  useEffect(() => {
    let formatted = [];
    if (users?.length > 0) {
      formatted = users.map((item) => {
        return {
          ...item,
          key: item?._id,
        };
      });
    }
    setFormattedUsers(formatted);
  }, [users]);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await getAllUsers();
      console.log(res?.data);
      if (res?.data?.users) {
        dispatch(setUsersAction(res.data.users));
      }
      setLoading(false);
      openNotification({
        type: "success",
        title: "Success",
        description: "Users fetched successfully",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        type: "error",
        title: "Error",
        description: "Something went wrong",
        rtl,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },
    {
      title: intl.formatMessage({ id: "exams" }),
      dataIndex: "exams",
      key: "exams",
      render: (exams, record) => {
        return (
          <span>
            {exams?.length}
            {record?.isVerified ? <MdVerified color={COLORS.primary} /> : null}
          </span>
        );
      },
      sorter: (a, b) => a.exams?.length - b.exams?.length,
    },
    {
      title: intl.formatMessage({ id: "score" }),
      dataIndex: "score",
      key: "score",
      render: (score, record) => {
        let totalScore = 0;
        let userScore = 0;
        let qCount = 0;
        record?.exams?.forEach((one) => {
          totalScore += one?.questionsCount * 10;
          userScore += one?.score;
          qCount += one?.questionsCount;
        });
        // convert to percentage of 100;
        let final =  (userScore / totalScore) * 100;
        return <Space direction="vertical">
          <Text className="my-font">{final?.toFixed(0)} %</Text>
          <Text type="secondary" style={{
            fontSize: 12
          }}>
            {qCount} {intl.formatMessage({ id: "question" })}
          </Text>
        </Space>;
      },
    },
    {
      title: "التاريخ",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return <span>{dayjs(createdAt).format("YYYY-MM-DD")}</span>;
      },
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          level={4}
          style={{
            margin: 0,
          }}
        >
          {intl.formatMessage({ id: "users" })}
        </Title>

        <CustomButton
          text={intl.formatMessage({ id: "refresh" })}
          onClick={getAllUsers}
          style={{ marginRight: 10 }}
          icon={<IoReloadOutline />}
          type='text'
        />
      </div>
      <div></div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: "20px 5px 0px 5px",
          }}
          className='light-shadow'
        >
          <Table
            searchableProps={{
              debounce: true,
              fuzzySearch: true,
              inputProps: {
                placeholder: "Search this table",
                prefix: <SearchOutlined />,
                disabled: loading || formattedUsers?.length === 0,
                onChange: (e) => {
                  setSearchValue(e.target.value);
                },
                value: searchValue,
              },
            }}
            columns={columns}
            style={{ borderRadius: 10 }}
            dataSource={formattedUsers}
            pagination={{
              // pageSize: 3,
              pageSizeOptions: [3, 5, 10, 50, 100, 200, 500, 1000],
              showSizeChanger: true,
            }}
            // pagination={{
            //   pageSize: 3,
            //   current: page,
            //   total: totalResult,
            //   showSizeChanger: false,
            // }}
            // onChange={(pagination) => {
            //   setPage(pagination.current)
            //   console.log(pagination)
            //   getQuestionByPage(pagination.current)
            // }}
            // scroll={{ x: 500 }}
            // pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Users;
