import React, { useEffect, useState } from "react";
import { Typography, Spin, Form, Input, Space } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { AiOutlineRollback } from "react-icons/ai";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { addStyles } from "react-mathquill";
import { editText } from "../../API/fetch";
import {
  addTextAction,
  updateTextAction,
} from "../../redux/reducers/questionsReducer";
import { cutText } from "../../helpers";
const { Title, Text } = Typography;
addStyles();

const EditText = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { texts } = useSelector((state) => state.questions);
  const [text, setText] = useState(null);
  const params = useParams();
  useEffect(() => {
    const selected = texts.find((item) => item?._id === params.id);
    if (selected) {
      setText(selected);
      form.setFieldsValue({
        text: selected?.text,
        title: selected?.title,
      });
    }
  }, [params.id, texts]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let textData = {
        text: values.text,
        title: values.title,
      };
      const data = {
        text: textData,
      };

      const res = await editText(text?._id, data);
      if (res?.data?.text) {
        dispatch(updateTextAction(res.data.text));
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: "تم تعديل النص بنجاح",
          type: "success",
          rtl: true,
        });

        setLoading(false);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 10,
        }}
      >
        <CustomButton
          text={intl.formatMessage({ id: "back" })}
          className={`btnRegister btn-text border-8 px-5 `}
          icon={<AiOutlineRollback size={16} />}
          style={{
            backgroundColor: COLORS.primary,
            color: COLORS.white,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <Title
          level={4}
          style={{
            margin: 0,
          }}
        >
          تعديل النص
        </Title>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: "20px 5px 20px 5px",
          }}
          className='light-shadow'
        >
          <Form
            form={form}
            layout='vertical'
            name='add-text'
            onFinish={onFinish}
            style={{ width: "100%" }}
            initialValues={{
              text: "",
              title: "",
            }}
          >
            {() => {
              return (
                <>
                  <Form.Item
                    label={intl.formatMessage({ id: "title" })}
                    name='title'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: "text" })}
                    name='text'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  <Form.Item>
                    <Space direction='horizontal' align='center'>
                      <CustomButton
                        htmlType='submit'
                        text={intl.formatMessage({ id: "save" })}
                        className={`btnRegister btn-text border-8 px-5 `}
                        loading={loading}
                        disabled={loading}
                        type='primary'
                      />

                      <Link
                        to='/questions'
                        style={{
                          margin: 0,
                          backgroundColor: COLORS.gray,
                          color: COLORS.primary,
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <AiOutlineRollback size={16} />
                        {intl.formatMessage({ id: "back" })}
                      </Link>
                    </Space>
                  </Form.Item>
                </>
              );
            }}
          </Form>
        </div>

        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: "20px 5px 20px 5px",
          }}
          className='light-shadow'
        >
          <Text
            style={{
              margin: 0,
              fontSize: 16,
              marginBottom: 10,
            }}
          >
            {intl.formatMessage({ id: "questions" })}
          </Text>
          {text?.questions?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: 10,
                  paddingTop: 10,
                  borderBottom: "1px solid #ccc",
                  backgroundColor: COLORS.gray,
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                  borderRadius: 15,
                }}
              >
                <Text
                  style={{
                    margin: 0,
                    fontSize: 16,
                  }}
                >
                  {cutText(item?.text, 40)}
                </Text>
                <Link
                  to={`/questions/${item?._id}`}
                  style={{
                    margin: 0,
                    backgroundColor: COLORS.primary,
                    color: COLORS.white,
                    padding: "5px 10px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  مشاهدة
                </Link>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

export default EditText;
