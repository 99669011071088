import React, { useEffect, useState } from "react";
import {
  Typography,
  Spin,
  Form,
  Input,
  Space,
  Checkbox,
  Select,
  Col,
  Row,
  Button,
  Upload,
} from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { PlusOutlined } from "@ant-design/icons";
import { AiOutlineDelete, AiOutlineRollback } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { setTextsAction, updateQuestionAction } from "../../redux/reducers/questionsReducer";
import { addImage, editQuestion, getAllTextApi } from "../../API/fetch";
import { addStyles, EditableMathField } from "react-mathquill";
import { cutText } from "../../helpers";
import { GrRefresh } from "react-icons/gr";

const { Title, Text } = Typography;
const { Dragger } = Upload;

addStyles();

const AddQuestion = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { questions, texts } = useSelector((state) => state.questions);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState({});
  const [showMathJax, setShowMathJax] = useState(false);
  const [latex, setLatex] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);

  // get the params from the url
  const params = useParams();
  useEffect(() => {
    const selected = questions.find((item) => item?._id === params.id);
    if (selected) {
      setQuestion(selected);
      form.setFieldsValue({
        text: selected?.text,
        choices: selected?.choices?.map((item) => {
          let correct = false;
          let isMath = false;
          let image = "";
          let latex = "";
          if (item.correct === true) {
            correct = true;
          }
          if (item.isMath === true) {
            isMath = true;
          }
          if (item.latex) {
            latex = item.latex;
          }
          if (item.image) {
            image = {
              uid: item.image,
              name: `${Math.floor(Math.random() * 1000)}-image`,
              status: "done",
              url: item.image,
            };
          }
          return {
            answer: item.text,
            correct,
            isMath,
            file: image,
            latex,
          };
        }),
        category: selected?.category,
        difficulty: selected?.difficulty,
        isMathJax: selected?.isMathJax,
        latex: selected?.latex,
        mathText: selected?.mathText,
        explainResult: selected?.explainResult,
        textId: selected?.textId,
      });
      if (selected?.isMathJax) {
        setShowMathJax(true);
        setLatex(selected?.latex);
        setText(selected?.mathText);
      }
      if (selected?.image) {
        setFile({
          uid: selected?.image,
          name: `${Math.floor(Math.random() * 1000)}-image`,
          status: "done",
          url: selected?.image,
        });
      }
    }
  }, [params.id, questions]);

  const onFinish = async (values) => {
    let allChoices = [];
    try {
      const selected = questions.find((item) => item?._id === params.id);
      setLoading(true);
      let questionImage = selected?.image;
      for (let i = 0; i < values?.choices.length; i++) {
        const item = values?.choices[i];
        let correct = false;
        let isMath = false;
        let image = "";
        let latex = "";
        if (item.correct === true) {
          correct = true;
        }
        if (item.isMath === true) {
          isMath = true;
        }
        if (item.latex) {
          latex = item.latex;
        }
        if (item.file) {
          // check if it is the same image;
          if (item.file?.uid === selected?.choices[i]?.image) {
            // do nothing;
            console.log("Same choice image");
            image = selected?.choices[i]?.image;
          } else {
            // upload the image;
            const formData = new FormData();
            formData.append("image", item.file);
            const res = await addImage(formData, "choice");
            if (res?.data?.path) {
              image = res.data.path;
            }
          }
        }
        let text = item.answer;

        if (latex && latex.length > 0 && item?.answer?.length <= 0) {
          text = latex;
        }
        allChoices.push({
          text: text,
          correct,
          isMath,
          image,
          latex,
        });
      } // end of choices loop;
      if (file) {
        // upload the image;

        // check if it is the same image;
        if (file?.uid === selected?.image) {
          // do nothing;
          console.log("Same question image");
          questionImage = selected?.image;
        } else {
          const formData = new FormData();
          formData.append("image", file);
          const res = await addImage(formData, "question");
         

          if (res?.data?.path) {
            questionImage = res.data.path;
          }
        }
      }
      if (showMathJax) {
        // check if the user has entered a latex equation
        if (latex.length <= 0 || text.length <= 0) {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: "يجب إدخال معادلة رياضية",
            type: "error",
            rtl: true,
          });
          return;
        }
      }
      let formattedQuestion = {
        text: values.text,
        choices: allChoices,
        category: values.category,
        difficulty: values.difficulty,
        isMathJax: showMathJax,
        latex,
        mathText: text,
        image: questionImage,
        explainResult: values.explainResult,
      };
      const data = {
        question: formattedQuestion,
      };

      const res = await editQuestion(question?._id, data);
      if (res?.data?.question) {
        dispatch(updateQuestionAction(res.data.question));
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "questionAdded" }),
          type: "success",
          rtl: true,
        });

        setLoading(false);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }
  };

  const getAllTexts = async () => {
    try {
      setLoading(true);
      const res = await getAllTextApi();
      if (res?.data?.texts) {
        dispatch(setTextsAction(res.data.texts));
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: "تم جلب كل النصوص",
          type: "success",
          rtl: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }
  };

  const dummyRequest = (props) => {
    setTimeout(() => {
      props.onSuccess("ok");
      setFile(props.file);
    }, 200);
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 10,
        }}
      >
        <CustomButton
          text={intl.formatMessage({ id: "back" })}
          className={`btnRegister btn-text border-8 px-5 `}
          icon={<AiOutlineRollback size={16} />}
          style={{
            backgroundColor: COLORS.primary,
            color: COLORS.white,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Title
          level={4}
          style={{
            margin: 0,
          }}
        >
          {intl.formatMessage({ id: "editQuestion" })}
        </Title>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: "20px 5px 20px 5px",
          }}
          className='light-shadow'
        >
          <Form
            form={form}
            layout='vertical'
            name='add-question'
            onFinish={(v) => onFinish(v, "BACK")}
            style={{ width: "100%" }}
            initialValues={{
              text: "",
              choices: [],
            }}
          >
            {() => {
              return (
                <>
                  <div>
                    <Checkbox
                      onChange={(e) => setShowMathJax(e.target.checked)}
                      className='my-font mb-5'
                      checked={showMathJax}
                    >
                      {intl.formatMessage({ id: "useMathJax" })}
                    </Checkbox>
                    {showMathJax ? (
                      <Link
                        style={{
                          color: COLORS.primary,
                          textDecoration: "underline",
                        }}
                        to='https://latex.codecogs.com/eqneditor/editor.php'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Text
                          style={{
                            color: COLORS.primary,
                            textDecoration: "underline",
                          }}
                          className='my-font'
                        >
                          موقع لإنشاء معادلات رياضية
                        </Text>
                      </Link>
                    ) : null}
                  </div>
                  <Form.Item
                    label={intl.formatMessage({ id: "question" })}
                    name='text'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  {/* Add draggable upload image; */}
                  <Text className='my-font'>أضف صورة</Text>
                  <Dragger
                    accept='image/jpg,image/jpeg,image/png'
                    listType='picture-card'
                    customRequest={dummyRequest}
                    maxCount={1}
                    fileList={file ? [file] : []}
                  >
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        تحميل
                      </div>
                    </div>
                  </Dragger>
                  {showMathJax ? (
                    <Space direction='vertical' size={10}>
                      <Text className='my-font'>المعادلة الرياضية</Text>
                      <input
                        style={{
                          direction: "ltr",
                          textAlign: "left",
                        }}
                        value={latex}
                        onChange={(e) => setLatex(e.target.value)}
                      />
                      <EditableMathField
                        className='mathquill-example-field'
                        latex={latex}
                        onChange={(mathField) => {
                          setLatex(mathField.latex());
                          setText(mathField.text());
                        }}
                        mathquillDidMount={(mathField) => {
                          setText(mathField.text());
                        }}
                      />
                      {/* <Text className='my-font'>معاينة</Text>
                <code>{text}</code>
                <code>{latex}</code> */}
                    </Space>
                  ) : null}
                  <Title level={4} className='my-font'>
                    {intl.formatMessage({ id: "answers" })}:{" "}
                  </Title>
                  <Form.List
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length !== 4) {
                            return Promise.reject(
                              new Error("يجب وضع اربعة خيارات")
                            );
                          }
                        },
                      },
                    ]}
                    name={"choices"}
                  >
                    {(fields, { add, remove }, { errors }) => {
                      return (
                        <div>
                          {fields.map(
                            ({ name, key, isListField, ...restField }) => {
                              let showAnswerMath =
                                form.getFieldValue("choices")?.[key]?.isMath;
                              return (
                                <Row
                                  key={key}
                                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                                  style={{
                                    justifyContent: "space-between",
                                    backgroundColor: COLORS.lightGray,
                                    alignItems: "center",
                                    borderRadius: 10,
                                    marginBottom: 20,
                                    marginRight: 10,
                                    marginLeft: 10,
                                  }}
                                >
                                  <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      label={intl.formatMessage({
                                        id: "answer",
                                      })}
                                      name={[name, "answer"]}
                                      rules={
                                        showAnswerMath
                                          ? []
                                          : [
                                              {
                                                required: true,
                                                message: intl.formatMessage({
                                                  id: "required",
                                                }),
                                              },
                                            ]
                                      }
                                    >
                                      <Input
                                        placeholder={intl.formatMessage({
                                          id: "answer",
                                        })}
                                      />
                                    </Form.Item>
                                    {/* Only show this if isMath is true; */}
                                    {showAnswerMath ? (
                                      <Space
                                        direction='horizontal'
                                        size={"middle"}
                                      >
                                        <>
                                          <Form.Item
                                            {...restField}
                                            label={"المعادلة الرياضية"}
                                            name={[name, "latex"]}
                                            rules={
                                              !showAnswerMath
                                                ? []
                                                : [
                                                    {
                                                      required: true,
                                                      message:
                                                        intl.formatMessage({
                                                          id: "required",
                                                        }),
                                                    },
                                                  ]
                                            }
                                          >
                                            <Input />
                                          </Form.Item>
                                        </>
                                        <EditableMathField
                                          className='mathquill-example-field'
                                          latex={
                                            form.getFieldValue("choices")?.[key]
                                              ?.latex
                                          }
                                          onChange={(mathField) => {
                                            let choices =
                                              form.getFieldValue("choices");
                                            choices[key].latex =
                                              mathField.latex();
                                            form.setFieldsValue({
                                              choices,
                                            });
                                          }}
                                          mathquillDidMount={(mathField) => {
                                            setText(mathField.text());
                                          }}
                                        />
                                      </Space>
                                    ) : null}
                                  </Col>
                                  <Col md={12} sm={24} xs={24}>
                                    <Space
                                      direction='horizontal'
                                      align='center'
                                      style={{
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Form.Item
                                        {...restField}
                                        label={intl.formatMessage({
                                          id: "isCorrect",
                                        })}
                                        name={[name, "correct"]}
                                        valuePropName='checked'
                                        rules={[
                                          // make user that we only have one correct answer;
                                          ({ getFieldValue }) => ({
                                            validator(_, value) {
                                              if (
                                                getFieldValue(
                                                  "choices"
                                                )?.filter(
                                                  (item) =>
                                                    item.correct === true
                                                ).length === 1
                                              ) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                new Error(
                                                  "يجب أن يكون هناك إجابة صحيحة واحدة على الأقل"
                                                )
                                              );
                                            },
                                          }),
                                        ]}
                                      >
                                        <Checkbox />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        label={intl.formatMessage({
                                          id: "isMathAnswer",
                                        })}
                                        name={[name, "isMath"]}
                                        valuePropName='checked'
                                      >
                                        <Checkbox
                                          onChange={(e) => {
                                            if (e.target.checked === false) {
                                              // reset the latex;
                                              let choices =
                                                form.getFieldValue("choices");
                                              choices[key].latex = "";
                                              form.setFieldsValue({
                                                choices,
                                              });
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <AiOutlineDelete
                                        color={COLORS.danger}
                                        onClick={() => remove(name)}
                                        size={20}
                                      />
                                    </Space>
                                  </Col>
                                  <Col md={24} sm={24} xs={24}>
                                    <Text className='my-font'>أضف صورة</Text>
                                    <Dragger
                                      accept='image/jpg,image/jpeg,image/png'
                                      listType='picture-card'
                                      customRequest={(props) => {
                                        props.onSuccess("ok");
                                        // set the file with the choice;
                                        let choices =
                                          form.getFieldValue("choices");
                                        choices[key].file = props.file;
                                        form.setFieldsValue({
                                          choices,
                                        });
                                      }}
                                      maxCount={1}
                                      fileList={
                                        form.getFieldValue("choices")?.[key]
                                          ?.file
                                          ? [
                                              form.getFieldValue("choices")?.[
                                                key
                                              ]?.file,
                                            ]
                                          : []
                                      }
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div
                                          style={{
                                            marginTop: 8,
                                          }}
                                        >
                                          تحميل
                                        </div>
                                      </div>
                                    </Dragger>
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                          <Form.Item>
                            <Button
                              type='dashed'
                              className='my-font'
                              disabled={fields.length >= 4}
                              onClick={() => add()}
                              style={{
                                width: "60%",
                              }}
                              icon={<PlusOutlined />}
                            >
                              {intl.formatMessage({ id: "addChoice" })}
                            </Button>
                          </Form.Item>
                          <Form.ErrorList errors={errors} className='danger' />
                        </div>
                      );
                    }}
                  </Form.List>
                  {/* explainResult */}
                  <Form.Item label={"شرح الإجابة"} name='explainResult'>
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  {/* category */}
                  <Row
                    className='mt-5'
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                  >
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "category" })}
                        name='category'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "required" }),
                          },
                        ]}
                      >
                        <Select
                          className='my-font'
                          style={{
                            width: "100%",
                          }}
                        >
                          {["كمي", "لفظي", "تحصيلي", "معلمين"].map(
                            (item, index) => {
                              return (
                                <Select.Option
                                  className='my-font'
                                  key={index}
                                  value={item}
                                >
                                  {item}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {form.getFieldValue("category") === "لفظي" ? (
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <Space direction='horizontal' align='center'>
                              <Text className='my-font'>
                                اختر القطعة المصاحبة
                              </Text>{" "}
                              <Text
                                onClick={getAllTexts}
                                className='my-font pointer'
                              >
                                تحديث <GrRefresh />
                              </Text>{" "}
                            </Space>
                          }
                          name='textId'
                        >
                          <Select
                            className='my-font'
                            style={{
                              width: "100%",
                            }}
                          >
                            {texts.map((item, index) => {
                              return (
                                <Select.Option
                                  className='my-font'
                                  key={index}
                                  value={item._id}
                                >
                                  {item.title} - {cutText(item.text, 20)}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "difficulty" })}
                        name='difficulty'
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "required" }),
                          },
                        ]}
                      >
                        <Select
                          className='my-font'
                          style={{
                            width: "100%",
                          }}
                        >
                          {["بسيط جداً", "بسيط", "عادي", "صعب", "صعب جداً"].map(
                            (item, index) => {
                              return (
                                <Select.Option
                                  className='my-font'
                                  key={index}
                                  value={item}
                                >
                                  {item}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    </Row>

                  <Form.Item>
                    <Space direction='horizontal' align='center'>
                      <CustomButton
                        htmlType='submit'
                        text={intl.formatMessage({ id: "save" })}
                        className={`btnRegister btn-text border-8 px-5 `}
                        loading={loading}
                        disabled={loading}
                        type='primary'
                      />

                      <Link
                        to='/questions'
                        style={{
                          margin: 0,
                          backgroundColor: COLORS.gray,
                          color: COLORS.primary,
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        <AiOutlineRollback size={16} />
                        {intl.formatMessage({ id: "back" })}
                      </Link>
                    </Space>
                  </Form.Item>
                </>
              );
            }}
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default AddQuestion;
