import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {

  const { isLoggedIn, userHasLevel } = useSelector((state) => state.auth);

 

  if (isLoggedIn === true) {
     if (userHasLevel === true) {
      return children;
    } else {
      return <Navigate to='/register' />;
    }
  } else {
    return <Navigate to='/register' />;
  }

  // if (userHasLevel === true && canAddRestaurant) {
  //   return children;
  // } else if (isLoggedIn === true && userHasLevel === false ) {
  //   return <EnterCode />;
  // }else if(isLoggedIn === true && userHasLevel === true && canAddRestaurant === false) {
  //   return children;
  // }else {
  //   return <Navigate to='/register' />;
  // }
};

export default PrivateRoute;
