import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import settingsReducer from "./settingsReducer";
import authReducer from "./authReducer";
import questionsReducer from "./questionsReducer";
import usersReducer from "./usersReducer";
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["settings", "auth", "questions", "users"],
  transforms: [], // TODO: secure some data?
};
const reducers = persistCombineReducers(persistConfig, {
  settings: settingsReducer,
  auth: authReducer,
  questions: questionsReducer,
  users: usersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logOut") {
    // check for action type
    state = {}; // reset state
    storage.removeItem("persist:root");
  }
  return reducers(state, action);
};

export default rootReducer;
