import React, { useState, useEffect } from "react";
import { Row, Typography, Select } from "antd";
import dayjs from "dayjs";

const { Text, Title } = Typography;

const Home = () => {
  return (
    <Row
      style={{
        minHeight: "calc(100vh - 100px)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Title level={5}>Home Page</Title>
    </Row>
  );
};

export default Home;
