import React, { useCallback, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import messages from "./messages";
import { useDispatch, useSelector } from "react-redux";
import {
  sefFullWidthAction,
  setIsOfflineAction,
} from "./redux/reducers/settingsReducer";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import openNotification from "./Components/Notifications";

import LoadingScreen from "./Components/LoadingScreen";

import { logOutAction } from "./redux/reducers/authReducer";

const App = () => {
  const { rtl, local, fullWidth } = useSelector((state) => state.settings);
  const { userHasLevel, isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);

  // handle resize the screen;
  const handleResizeScreen = useCallback(() => {
    if (fullWidth !== window.innerWidth) {
      let isBigScreen = window.innerWidth >= 600 ? true : false;
      dispatch(
        sefFullWidthAction({ isBigScreen, fullWidth: window.innerWidth })
      );
    }
  }, [dispatch, fullWidth]);
  // handle internet connections;
  const handleInternetConnection = useCallback(
    (value) => {
      dispatch(setIsOfflineAction(value));
    },
    [dispatch]
  );

  const getInitialData = async () => {
    try {
      console.log("get initial data");
      setLoadingData(false);
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data === "Unauthorized") {
        dispatch(logOutAction());
      } else {
        setLoadingData(false);

        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn && userHasLevel) {
      getInitialData();
    } else {
      setLoadingData(false);
    }
  }, [isLoggedIn, userHasLevel]);

  // listeners for internet and resize screen;
  useEffect(() => {
    if (navigator && navigator.onLine === false) {
      handleInternetConnection(true);
    }
    window.addEventListener("online", () => handleInternetConnection(false));
    window.addEventListener("offline", () => handleInternetConnection(true));
    window.addEventListener("resize", handleResizeScreen);
    return () => {
      window.removeEventListener("online", () =>
        handleInternetConnection(false)
      );
      window.removeEventListener("offline", () =>
        handleInternetConnection(true)
      );
      window.addEventListener("resize", handleResizeScreen);
    };
  }, [handleInternetConnection, handleResizeScreen]);
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#2DA996", colorError: "#FC4C3A" } }}
      direction={rtl ? "rtl" : "ltr"}
    >
      <IntlProvider locale={local} messages={messages[local]}>
        <Router>{loadingData ? <LoadingScreen /> : <AllRoutes />}</Router>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
