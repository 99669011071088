import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  questions: [],
  texts: [],
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setTexts: (state, action) => {
      state.texts = action.payload;
    },
    addQuestion: (state, action) => {
      state.questions = arrAdd({
        arr: state.questions,
        newElement: action.payload,
      });
    },
    addText: (state, action) => {
      state.texts = arrAdd({
        arr: state.texts,
        newElement: action.payload,
      });
    },
    updateQuestion: (state, action) => {
      state.questions = arrUpdateByKeyValue({
        arr: state.questions,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
    updateText: (state, action) => {
      state.texts = arrUpdateByKeyValue({
        arr: state.texts,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
  },
});

export const {
  setQuestions: setQuestionsAction,
  addQuestion: addQuestionAction,
  updateQuestion: updateQuestionAction,
  setTexts: setTextsAction,
  addText: addTextAction,
  updateText: updateTextAction,
} = questionsSlice.actions;

export default questionsSlice.reducer;
