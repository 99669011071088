const messages = {
  ar: {
    home: "الرئيسية",
    oppLanguage: "English",
    arabic: "العربية",
    english: "الإنجليزية",
    signout: "تسجيل الخروج",
    settings: "الإعدادات",
    signin: "تسجيل الدخول",
    signup: "إنشاء حساب",
    name: "ادخل اسمك",
    phone: "رقم الجوال",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gmail.com",
    email: "البريد الالكتروني",
    error: "خطأ",
    errorname: "يجب كتابة الاسم",
    errorphone: "5xxxxxxxx",
    erroremail: "تأكد من البريد الالكتروني",
    password: "الرقم السري",
    errorpassword: "الرقم السري يحب أن يكون أكثر من ٦ خانات",
    errorfetch: "خطأ في الاتصال, فضلاً أعد المحاولة لاحقاً",
    choose: "اختر",
    delete: "حذف",
    status: "الحالة",
    edit: "تعديل",
    confirmDelete: "هل أنت متأكد من حذف هذا السجل ؟",
    cancel: "إلغاء",
    save: "حفظ",
    maxSelection: "الحد الأعلى من الخيارات",
    minSelection: "الحد الأدنى من الخيارات",
    errorCode: "الكود غير صحيح",
    enter: "ادخل",
    justName: "الاسم",
    questionsBank: "بنك الأسئلة",
    admin: "المشرفين",
    loadingData: "جاري تحميل البيانات",
    addNewQuestion: "إضافة سؤال جديد",
    back: "رجوع",
    question: "السؤال",
    required: "مطلوب",
    addChoice: "إضافة خيار",
    answers: "الإجابات",
    isCorrect: "هل الإجابة صحيحة ؟",
    answer: "الإجابة",
    category: "التصنيف",
    difficulty: "الصعوبة",
    refresh: "تحديث",
    doneSuccessfully: "تمت العملية بنجاح",
    somethingWentWrong: "حدث خطأ ما",
    success: "تم بنجاح",
    editQuestion: "تعديل السؤال",
    questionAdded: "تم إضافة السؤال بنجاح",
    useMathJax: "استخدام سؤال رياضيات",
    isMathAnswer: " الإجابة رياضية",
    addNewText: "أضف قطعة نص جديدة",
    title: "العنوان",
    text: "النص",
    texts: "النصوص",
    questions: "الأسئلة",
    users: "المستخدمين",
    exams: "الاختبارات",
    score: "النتيجة",
  },
  en: {
    home: "Home",
    score: "Score",
    exams: "Exams",
    users: "Users",
    questions: "Questions",
    texts: "Texts",
    title: "Title",
    text: "Text",
    addNewText: "Add new text",
    isMathAnswer: "Math Answer",
    useMathJax: "Use MathJax",
    questionAdded: "Question added successfully",
    editQuestion: "Edit Question",
    success: "Success",
    doneSuccessfully: "Done Successfully",
    somethingWentWrong: "Something went wrong",
    refresh: "Refresh",
    difficulty: "Difficulty",
    category: "Category",
    answer: "Answer",
    isCorrect: "Is Correct ?",
    answers: "Answers",
    addChoice: "Add Choice",
    required: "Required",
    question: "Question",
    back: "Back",
    addNewQuestion: "Add new question",
    loadingData: "Loading Data",
    admin: "Admin",
    questionsBank: "Questions Bank",
    oppLanguage: "عربي",
    settings: "Settings",
    signout: "Sign out",
    signin: "Sign in",
    signup: "Sign up",
    name: "Name",
    phone: "Mobile Number",
    phone05: "5xxxxxxxx",
    email: "Email",
    error: "Error",
    errorname: "Name is required",
    errorphone: "5xxxxxxxx",
    erroremail: "Please check your email",
    password: "Password",
    errorpassword: "Minimum length is 6 characters",
    errorfetch: "There was an error in the connection please try again.",
    nameAr: "Name in Arabic",
    nameEn: "Name in English",
    choose: "Choose",
    delete: "Delete",
    status: "Status",
    edit: "Edit",
    confirmDelete: "Are you sure you want to delete this record ?",
    cancel: "Cancel",
    save: "Save",
    maxSelection: "Maximum choices",
    minSelection: "Minimum choices",
    code: "Enter your dashboard pin code",
    errorCode: "Code is not correct",
    enter: "Enter",
    justName: "Name",
    confirmDeleteReferTitle: "Are you sure you want to Delete this code ?",
    forgetPassword: "Forget password",
  },
};

export default messages;
