import React, { useState, useEffect } from "react";
import { Typography, Spin } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../Style/colors";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { BsPlus } from "react-icons/bs";
import CustomButton from "../../Components/CustomButton";
import { setQuestionsAction, setTextsAction } from "../../redux/reducers/questionsReducer";
import openNotification from "../../Components/Notifications";
import { getAllTextApi, getQuestions } from "../../API/fetch";
import dayjs from "dayjs";
import { getByText } from "@testing-library/react";
import { cutText } from "../../helpers";
const { Title } = Typography;

const Texts = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { texts } = useSelector((state) => state.questions);
  const [formattedQuestions, setFormattedQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedResults, setSearchedResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [page, setPage] = useState(1)
  // const [nexUrl, setNexUrl] = useState('');
  // const [prevUrl, setPrevUrl] = useState('');
  const [totalResult, setTotalResult] = useState(0)

  useEffect(() => {
    if (searchValue && searchValue?.trim()?.length > 0) {
      let copy = [...formattedQuestions];
      copy = copy.filter((el) => {
        return el?.text?.includes(searchValue);
      });
      setSearchedResults(copy);
    } else {
      setSearchedResults([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (texts?.length) {
      const formatted = texts.map((item) => {
        return {
          ...item,
          key: item?._id,
          questions: item?.questions?.length,
        };
      });
      setFormattedQuestions(formatted);
    } else {
      setFormattedQuestions([]);
    }
  }, [texts]);

  const getAllTextWithQuestions = async (newPage) => {
    try {
      setLoading(true);
     
      const res = await getAllTextApi();
      if (res?.data) {
        dispatch(setTextsAction(res?.data?.texts));
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "doneSuccessfully" }),
          type: "success",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "somethingWentWrong" }),
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Link to={`${record._id}`}>{index + 1}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "title" }),
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return <Link to={`${record._id}`}>{text}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "text" }),
      dataIndex: "text",
      key: "text",
      width: 300,
      render: (text, record) => {
        return <Link to={`${record._id}`}>{cutText(text, 80)}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "questions" }),
      dataIndex: "questions",
      key: "questions",
    },
    {
      title: 'التاريخ',
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return <span>{dayjs(createdAt).format('YYYY-MM-DD')}</span>;
      },
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
    }

  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 10,
        }}
      >
        <Title
          level={4}
          style={{
            margin: 0,
          }}
        >
          {intl.formatMessage({ id: "texts" })}
        </Title>
        <Link
          to='/add-text'
          style={{
            margin: 0,
            backgroundColor: COLORS.darkGray,
            color: COLORS.black,
            padding: "5px 10px",
            display: "flex",
            alignItems: "center",
            borderRadius: 5,
          }}
        >
          <BsPlus size={20} />
          {intl.formatMessage({ id: "addNewText" })}
        </Link>

       
       
      </div>
      <div>
        <CustomButton
          text={intl.formatMessage({ id: "refresh" })}
          onClick={getAllTextWithQuestions}
          style={{ marginRight: 10 }}
          // icon={<ReloadOutlined />}
          type='text'
        />
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: "20px 5px 0px 5px",
          }}
          className='light-shadow'
        >
          <Table
            searchableProps={{
              debounce: true,
              fuzzySearch: true,
              inputProps: {
                placeholder: "Search this table",
                prefix: <SearchOutlined />,
                disabled: loading || formattedQuestions?.length === 0,
                onChange: (e) => {
                  setSearchValue(e.target.value);
                },
                value: searchValue,
              },
            }}
            columns={columns}
            style={{ borderRadius: 10 }}
            dataSource={formattedQuestions}
            pagination={{
              // pageSize: 3,
              pageSizeOptions: [3,5,10, 50, 100, 200, 500, 1000],
              showSizeChanger: true,
            }}
            // pagination={{
            //   pageSize: 3,
            //   current: page,
            //   total: totalResult,
            //   showSizeChanger: false,
            // }}
            // onChange={(pagination) => {
            //   setPage(pagination.current)
            //   console.log(pagination)
            //   getAllTextWithQuestions(pagination.current)
            // }}
            // scroll={{ x: 500 }}
            // pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Texts;
