import { Button } from 'antd';
import React from 'react';

function CustomButton({ text, onClick, loading, className, disabled, ...props }) {
    return (
        <Button
            {...props}
            disabled={disabled}
            onClick={onClick}
            className={`${className} ${'my-font'}`}
            loading={loading}>
            {text}
        </Button>
    );
}


export default CustomButton;