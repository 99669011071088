import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Home from "./Views/Home";
import Questions from "./Views/Questions";
import AddQuestion from "./Views/AddQuestion";
import EditQuestion from "./Views/EditQuestion";
import AddText from "./Views/Texts/AddText";
import EditText from "./Views/Texts/EditText";
import Texts from "./Views/Texts";
import Users from "./Views/Users";



const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline, intl]);

 

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/questions'
        element={
          <PrivateRoute>
            <Sidebar component={<Questions />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/questions/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditQuestion />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/add-question'
        element={
          <PrivateRoute>
            <Sidebar component={<AddQuestion />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/texts'
        element={
          <PrivateRoute>
            <Sidebar component={<Texts />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/add-text'
        element={
          <PrivateRoute>
            <Sidebar component={<AddText />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/users'
        element={
          <PrivateRoute>
            <Sidebar component={<Users />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/texts/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<EditText />} />
          </PrivateRoute>
        }
      />
     
      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
