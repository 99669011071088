import React from "react";
import { Menu } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  IoHomeOutline,
  IoLogOut,

  IoLanguageOutline,
} from "react-icons/io5";
import { GrFormFolder, GrUserAdmin, GrUserSettings } from "react-icons/gr";
import CustomButton from "../CustomButton";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import {BsFillPatchQuestionFill} from 'react-icons/bs';


const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const items = [
    {
      key: "home",
      icon: <IoHomeOutline size={20} />,
      label: <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>,
    },
    // questions;
    {
      key: "questions",
      icon: <BsFillPatchQuestionFill size={20} />,
      label: <Link to='/questions'>{intl.formatMessage({ id: "questionsBank" })}</Link>,
    },
    {
      key: "texts",
      icon: <GrFormFolder size={20} />,
      label: <Link to='/texts'>{intl.formatMessage({ id: "texts" })}</Link>,
    },
    {
      key: "users",
      icon: <GrUserSettings size={20} />,
      label: <Link to='/users'>{intl.formatMessage({ id: "users" })}</Link>,
    },

    {
      key: "language",
      icon: <IoLanguageOutline size={20} />,
      label: (
        <CustomButton
          onClick={() => dispatch(changeLanguageAction())}
          text={intl.formatMessage({ id: "oppLanguage" })}
          type="text"
        />
      ),
    },
    {
      key: "signOut",
      icon: <IoLogOut size={20} />,
      label: (
        <CustomButton
          onClick={() => dispatch(logOutAction())}
          text={intl.formatMessage({ id: "signout" })}
          type="text"
        />
      ),
    },
  ];
  const adminItems = [
    {
      key: "admin",
      icon: <GrUserAdmin size={20} />,
      label: <Link to='/admin'>{intl.formatMessage({ id: "admin" })}</Link>,
    },
  ];
  
  return (
    <Menu
      items={user?.role === 'admin' ? [
        ...items,
        ...adminItems,
      ]: items}
      theme='light'
      mode='inline'
      defaultSelectedKeys={["1"]}
    />
  );
};

export default SuperUserSide;
