import { api } from "./index";

export const signin = (data) => {
  return api.post(`/login`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getWhatsappQr = () => {
  return api.get(`/getqr`);
};

export const resetWhatsaapQr = () => {
  return api.get(`/resetqr`, { timeout: 1000 * 60 * 10 });
};

export const checkAuth = () => {
  return api.get(`/checkauth`);
};

export const getAdminData = () => {
  return api.get(`/admin-data`);
};

export const postQuestion = (data) => {
  return api.post(`/questions`, data);
};

export const getQuestions = (params) => {
  console.log(params);
  return api.get(`/questions`, { params });
};

export const editQuestion = (id, data) => {
  return api.patch(`/questions/${id}`, data);
};

export const addImage = (data, type) => {
  return api.post(`/images?type=${type}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postText = (data) => {
  return api.post(`/texts`, data);
}

export const getAllTextApi = () => {
  return api.get(`/texts`);
}

export const editText = (id, data) => {
  return api.patch(`/texts/${id}`, data);
}

export const getAllUsers = () => {
  return api.get(`/users`);
}